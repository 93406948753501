// Generated by Framer (9acc586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LSeXlnACv"];

const variantClassNames = {LSeXlnACv: "framer-v-1oshcf3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "LSeXlnACv", image: sMH8gtT11 = new URL("assets/xSRbs834iw3YIHPqWAxm6m64.webp", import.meta.url).href, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LSeXlnACv", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QbQtz", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1oshcf3", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"LSeXlnACv"} ref={ref} style={{...style}} transition={transition}><Image __framer__animate={{opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, transition: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, x: 0, y: 0}} __framer__animateOnce __framer__enter={{opacity: 0, scale: 1, x: 0, y: 0}} __framer__exit={{opacity: 0, scale: 1, transition: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, x: 0, y: 0}} __framer__threshold={0.5} alt={""} background={{fit: "fit", intrinsicHeight: 1285, intrinsicWidth: 2400, pixelHeight: 1285, pixelWidth: 2400, src: sMH8gtT11}} className={"framer-ipsvdc"} layoutDependency={layoutDependency} layoutId={"gNR2VrX06"} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QbQtz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QbQtz * { box-sizing: border-box; }", ".framer-QbQtz .framer-1oshcf3 { height: 350px; overflow: hidden; position: relative; width: 560px; }", ".framer-QbQtz .framer-ipsvdc { aspect-ratio: 1.5533980582524272 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 309px); left: calc(50.00000000000002% - 480px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 309px / 2); width: 480px; will-change: transform; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 350
 * @framerIntrinsicWidth 560
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"sMH8gtT11":"image"}
 */
const FramerjeWuE3vdh: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerjeWuE3vdh;

FramerjeWuE3vdh.displayName = "Devices";

FramerjeWuE3vdh.defaultProps = {height: 350, width: 560};

addPropertyControls(FramerjeWuE3vdh, {sMH8gtT11: {__defaultAssetReference: "data:framer/asset-reference,xSRbs834iw3YIHPqWAxm6m64.webp?originalFilename=Devices.webp&preferredSize=auto", title: "Image", type: ControlType.Image}} as any)

addFonts(FramerjeWuE3vdh, [])